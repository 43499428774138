$bg: #211f24;
$text: #ebebeb;
$muted: #a4a4a4;
$link: #47d7e2;
$line: #373738;
$hover: #242424;

/* Fonts */
$font-size-mobile: 14px;
$font-size-desktop: 16px;
$font-family: "Poppins";

/* Sizes */
$max-width: 640px;
$breakpoint: 992px;

/* Transitions */
$transition-duration: 0.2s;
