@import "mixins";

.link {
  @include flex(space-between);

  border-bottom: 1px solid $line;
  padding: 20px 15px;

  .item:first-child & {
    border-top: 1px solid $line;
  }

  &:hover {
    background: $hover;
  }
}
