*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: "Gotham", sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  background: $bg;
  color: $text;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;

  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 500;
  margin: 0;
}

p,
ul,
ol,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

strong,
.strong {
  font-weight: 500;
}

small,
.small {
  font-size: 75%;
}

a {
  color: inherit;
  text-decoration: none;
}

[target="_blank"] {
  @include link($link, $link);
  &:hover {
    text-decoration: none;
  }
}

::-webkit-scrollbar {
  display: none;
}
