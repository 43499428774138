@import "variables";

@mixin desktop {
  @media (min-width: ($breakpoint)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $breakpoint - 0.02) {
    @content;
  }
}

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin link($initial: fade-out(white, 0.5), $hover: white) {
  @include transition(color);
  color: $initial;

  &:hover,
  &.active {
    color: $hover;
  }
}

@mixin transition($property: all) {
  transition: $property $transition-duration;
}

/* app */
@mixin app {
  @include flex;

  @include mobile {
    font-size: $font-size-mobile;
  }

  @include desktop {
    font-size: $font-size-desktop;
  }

  position: relative;
  min-height: 100vh;
}
