.desktop {
  @include mobile {
    display: none !important;
  }
}

.mobile {
  @include desktop {
    display: none !important;
  }
}

.muted {
  color: $muted;
}
